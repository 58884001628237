<template lang="pug">
	.page
		Menu
			template(#title)
				.flex.items-center
					| Content Integrity
					Tag.mt-4.ml-8(:label="target" type="green")

		template(v-if="isMounted")
			Tabs.my-30(:tabs="tabItems" type-tab="tabs" v-model="tabComponents")

			.container
				.flex
					select(v-model="group")
						option(v-for="(item, i) in groupOptions" :key="i" :value="item") Group By: {{ item }}
					Tabs.ml-auto(:tabs="tabsData" type-tab="table-tabs" v-model="activeTab")

				component(
					:is="tabComponents"
					:response="response"
					:isLoading="isLoading"
					:group="group"
					:activeGroup="activeGroup"
					:activeTab="activeTab"
				)

		.empty-filter(v-else) Пожалуйста, выберите значения фильтра

</template>

<script>
import { renameYandexForContentIntegrity } from '@/helpers/RenameYandexPokupki'
import HelpCircle from 'vue-material-design-icons/HelpCircle.vue'
import Menu from '@/components/Menu/Menu.vue'
import Tag from '@/components/Tag/Tag.vue'
import Tooltip from '@/components/Elements/Tooltip.vue'
import Tabs from "@/components/Nestle/Tabs"
import ProductCompliance from "@/components/Nestle/ProductCompliance"
import Issue from "@/components/Nestle/Issue"

export default {
	name: "ContentIntegrity",
	components: {
		Issue,
		ProductCompliance,
		Tabs,
		HelpCircle,
		Menu,
		Tag,
		Tooltip,
	},

	props: {
		needUpdateResult: {
			type: Boolean,
			default: false,
		},
		date: {
			type: Object,
			default: () => {},
		},

		stores: {
			type: String,
			default: '',
		},
		categories: {
			type: String,
			default: '',
		},
		brands: {
			type: String,
			default: '',
		},
		regions: {
			type: String,
			default: '',
		},
		dateFrom: {
			type: String,
			default: '',
		},
		dateTo: {
			type: String,
			default: '',
		},
	},

	data() {
		return {
			target: '75%',
			tabComponents: 'ProductCompliance',
			activeTab: 'Sku',
			response: null,
			current: 1,
			limit: 20,
			offset: 0,
			isLoading: true,
			groupOptions: ['Store', 'Brand', 'Category'],
			group: 'Store',

			groupMapping: {
				Store: {
					name: 'stores',
				},
				Brand: {
					name: 'brands',
				},
				Category: {
					name: 'category',
				},
			},
			isMounted: false,
		}
	},

	computed: {
		activeGroup() {
			return this.groupMapping[this.group].name
		},

		tabsData() {
			return [
				{
					value: 'Sku',
					title: 'Product'
				},
				{
					value: 'Rule',
					title: 'Rule'
				},
			]
		},
		tabItems(){
			return [
				{
					title: 'Product Compliance',
					value: 'ProductCompliance'
				},
				{
					title: 'Issue',
					value: 'Issue'
				},
			]
		},
	},
	methods: {
		async exportHandler() {
			// Временно отдаем статический файл, пока не готовы отчеты для export

			// const params = {
			// 	stores: this.stores,
			// 	categories: this.categories,
			// 	brands: this.brands,
			// 	regions: this.regions,
			// 	dateFrom: this.dateFrom,
			// 	dateTo: this.dateTo,
			// };
			// try {
			// 	const image = await this.$api.nestle.getExportContentIntegrity(params)
			// 	const fileURL = window.URL.createObjectURL(
			// 		new Blob(
			// 			[image], 
			// 			{ type: "application/ vnd.openxmlformats - officedocument.spreadsheetml.sheet; charset = UTF-8" }
			// 		)
			// 	)
			// 	const fileLink = document.createElement('a')

			// 	fileLink.href = fileURL
			// 	fileLink.setAttribute('download', 'export-contentIntegrity.xlsx')
			// 	document.body.appendChild(fileLink)
			// 	fileLink.click()
			// } catch (error) {
			// 	console.log(error)
			// }
			
			try {
				const fileLink = document.createElement('a')

				fileLink.href = 'https://storage.yandexcloud.net/nestle/Content%20Integrity%20Checked.xlsx'
				fileLink.setAttribute('download', 'export-contentIntegrity.xlsx')
				document.body.appendChild(fileLink)
				fileLink.click()
			} catch (error) {
				console.log(error)
			}
		},

		async fetch(){
			this.isMounted = true

			const params = {
				stores: this.stores,
				categories: this.categories,
				brands: this.brands,
				regions: this.regions,
				category: this.group,
				dateFrom: this.dateFrom,
				dateTo: this.dateTo,
				offset: this.offset,
				limit: this.limit,
			};
			try {
				this.isLoading = true
				const result = await this.$api.nestle.getContentIntegrity(params)

				renameYandexForContentIntegrity(result)
				this.response = result
			} catch (error) {
				console.log(error)
			} finally {
				this.isLoading = false
			}
		},

	},

	watch: {
		needUpdateResult: {
			async handler() {
				console.log('NeedUpateResult')
				this.offset = 0
				this.current = 1
				await this.fetch()
			}
		},

		current: {
			handler() {
				this.offset = this.limit * (this.current-1)
				this.fetch()
			},
		},

	}
}
</script>

<style lang="scss" scoped>
select{
	padding: 0 5px;
	outline: 1px color(gray-400) solid;
	border-radius: 3px;
	color: color(gray-700);

	&:focus{
		outline: none;
	}
}

.page {
	max-width: 1280px;
	margin: 0 auto;
	&__nav {
		width: 190px;
	}
	&__date {
		width: 100px;
		color: color(white);
		cursor: pointer;

		::v-deep .control__input{
			color: color(white);
			font-weight: 400;
		}
	}
}
.empty-filter {
	font-size: 16px;
    display: flex;
    justify-content: center;
    margin-top: 30px;
}

</style>
